import classes from './OneImageGodina.module.css';

const OneImageGodina = ({ url, naslov, blur }) => {

    

    if (url && (url[0] !== "")) {
        return (
            
                <img src={url} alt={naslov} style={{ WebkitFilter: 'blur(' + blur + 'px)', filter: 'blur(' + blur + 'px)' }} width='50' height='50' loading="lazy"></img>
            
        )
    }
    else {
        return (
            <div className={classes.my}>{naslov}</div>
        )
    }
}

export default OneImageGodina;