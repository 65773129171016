import React from 'react';
import { useParams } from "react-router-dom";
/*import Galerija from '../Components/Galerija';*/
import axios from "axios";
import { useState, useEffect } from 'react';
import Spinner from '../Components/Spinner';
/*import classes from './Projekti.module.css';*/
/*import { NavLink } from '../../node_modules/react-router-dom/dist/index';*/
import SwiperSlider from '../Components/Swiper';
/*import classes from './OneProjekt.module.css';*/
import OneImage from '../Components/OneImage';

/*var items = [];*/

var naslov, featured_image, blur, godina, faza, lokacija, opis_zahvata, m2, rb;



export default function OneProjekt(props) {



    let { RB } = useParams();

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    const xxx = `https://divapi3.kopljar.com/projekt?id=` + RB;

    useEffect(() => {
        window.scrollTo(0, 0);
        const getData = async () => {
            try {
                const response = await axios.get(xxx);
                setData(response.data);
                setError(null);
            } catch (err) {
                setError(err.message);
                setData(null);
            } finally {
                setLoading(false);
            }
        };
        getData();
    }, [xxx]);


    if (data) {
        naslov = data.map(({ objekt }) => objekt)
        featured_image = data.map(({ featured }) => featured)
        blur = data.map(({ blur }) => blur)
        godina = data.map(({ godina }) => godina)
        faza = data.map(({ faza }) => faza)
        lokacija = data.map(({ lokacija }) => lokacija)
        opis_zahvata = data.map(({ opis_zahvata }) => opis_zahvata)
        m2 = data.map(({ m2 }) => m2)
        rb = data.map(({ rb }) => rb)

        data.map(({ rb, m2, godina, objekt, faza, lokacija, opis_zahvata, featured, blur }) =>
            <div>
                <p>{rb}</p>
                <p>{godina}</p>
                <p>{objekt}</p>
                <p>{faza}</p>
                <p>{lokacija}</p>
                <p>{opis_zahvata}</p>
                <p>{m2}</p>
                <p>{rb}</p>
                <p>{featured}</p>
                <p>{blur}</p>
            </div>
        );
    }



    return (
        <div>

            {loading &&
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <Spinner></Spinner>
                </div>
            }
            {error && (
                <div>{`Problem - ${error}`}</div>
            )}


            <div>
                <h1>{naslov}</h1>
                <h2>{lokacija}</h2>
            </div>
            <div className="my_separator"></div>
            <div>
                <p className="always_center">Godina: {godina}</p>
                <p className="always_center">Opis projekta: {opis_zahvata}</p>
                <p className="always_center">Faza: {faza}</p>
                <p className="always_center">Površina: {m2} m<sup>2</sup>  </p>
            </div>

            <div className="my_separator">
                {/*<a href={featured_image}>*/}
                   {/* <img src={featured_image} alt={naslov} className="featured_img" style={{ WebkitFilter: 'blur(' + blur + 'px)', filter: 'blur(' + blur + 'px)' }} ></img>*/}

                   <OneImage url={featured_image} naslov={naslov} blur={blur}></OneImage>
                    {/*<img src={featured_image} alt={naslov} className={classes.op_featured_img} style={{ WebkitFilter: 'blur(' + blur + 'px)', filter: 'blur(' + blur + 'px)' }} ></img>*/}
                {/*</a>*/}
            </div>
            <div>
                <SwiperSlider projektID={RB} blur={blur} visina='100'></SwiperSlider>
                {/*<Galerija projektID={RB} blur={blur} visina='100'></Galerija>*/}
            </div>

            
            


            {/*<div >*/}
            {/*    {*/}
            {/*        items.map((p) => <div>{p}</div>)*/}

            {/*    }*/}
            {/*</div>*/}
        </div>
    );



}


