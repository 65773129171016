import React from 'react';
import Galerija from '../Components/Galerija';
import SwiperSlider from '../Components/SwiperHome2';

export default function Home() {

    
    return (
        <div className="App">

            <SwiperSlider projektID="0" blur="0"></SwiperSlider>

            {/*<Galerija projektID="0" blur="0"></Galerija>*/}

        </div>
        
    );



}


