import './App.css';
import {
    BrowserRouter,
    Routes, //replaces "Switch" used till v5
    Route,
} from 'react-router-dom';

import { ReactComponent as Day } from './Images/day.svg';
import { ReactComponent as Night } from './Images/night.svg';

import { createContext, useState, useEffect } from "react";


import Home from './Pages/Home';
import Ured from './Pages/Ured';
import Projekti from './Pages/Projekti2';
import Kontakt from './Pages/Kontakt';
import Header from './Components/Header';
import Footer from './Components/Footer';
import ReactSwitch from '../node_modules/react-switch/index';
import Godina from './Pages/Godina';
import OneProjekt from './Pages/OneProjekt';
import ScrollToTop from './Components/ScrollToTop';

 
export const ThemeContext = createContext(null);

var boja;
var kontraboja;



function App() {

    useEffect(() => {
        const handlePopstate = () => {
            sessionStorage.setItem('scrollPosition', window.scrollY.toString());
        };

        window.addEventListener('popstate', handlePopstate);

        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, []);

    const [theme, setTheme] = useState('dark');
    const toggleTheme = () => {
        setTheme((curr) => (curr === 'light' ? 'dark' : 'light'));
        if (theme === 'dark') {
            document.documentElement.style.setProperty('--my-color', 'black');
        }
        if (theme === 'light') {
            document.documentElement.style.setProperty('--my-color', 'white');
        }    
    }

    boja = theme === 'dark' ? 'black' : 'white';
    kontraboja = theme === 'dark' ? 'white' : 'black';
    document.body.style.backgroundColor = boja;




    return (
        <ThemeContext.Provider value={{ theme, toggleTheme}}>

            <BrowserRouter>
                <ScrollToTop />
        
                <div className="App" id={theme}>
                    <Day stroke={kontraboja} fill={kontraboja}></Day>
                    <ReactSwitch onChange={toggleTheme} checked={theme === 'dark'}></ReactSwitch>
                    <Night stroke={kontraboja} fill={kontraboja}></Night>

                    <Header theme={theme}></Header>
                {/*<hr></hr>*/}
                <Routes>
                    <Route path='/' element={<Home />} exact></Route>
                    <Route path='/ured' element={<Ured />}></Route>
                    <Route path='/projekti' element={<Projekti />}></Route>
                    <Route path='/kontakt' element={<Kontakt />}></Route>
                    <Route path='/godina/:godina' element={<Godina />}></Route>
                    <Route path='/projekt/:RB' element={<OneProjekt />}></Route>
                </Routes>

                    
                    <Footer theme={theme}></Footer>
            </div>

            </BrowserRouter>
        </ThemeContext.Provider>
    );

}

export default App;

