import React from 'react';
import Spinner from '../Components/Spinner';
import axios from "axios";
import { useState, useEffect } from 'react';


// core version + navigation, pagination modules:
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation,Autoplay } from 'swiper/modules';
// import Swiper and modules styles

import 'swiper/css';
import 'swiper/css/bundle';
import '../App.css';

import Component from './Component';


var items = [];



export default function SwiperSlider({ projektID, blur }) {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

   

    /*const xxx = `https://divapi2.kopljar.com/x?id=${projektID}`;*/
    const xxx = `https://divapi3.kopljar.com/poc`;

    items = [];

  
    
    useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.get(xxx);
                setData(response.data);
                setError(null);
            } catch (err) {
                setError(err.message);
                setData(null);
            } finally {
                setLoading(false);
            }
        };
        getData();
    }, [xxx]);

    


    if (data) {
        items = data.map(({ url, naziv, lokacija1, lokacija2, blur }) =>
            <SwiperSlide key={url}>
                <Component url={url} naziv={naziv} lokacija1={lokacija1} lokacija2={lokacija2} blur={blur}></Component>
            </SwiperSlide>
        
        );
    }






    return (

        <div>
            {loading &&
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <Spinner></Spinner>
                </div>
            }
            {error && (

                <div>{`Problem - NEMA SLIKA !`}</div>
            )}

            <Swiper
                modules={[Navigation, Autoplay]}
                autoplay={{
                    delay: 12000
                }}

                navigation={true}
                
                grabCursor={true}
                crossfade={'true'}
                onSlideChange={() => {
                    console.log('slide change');
                }
                }
                onSwiper={(swiper) => console.log(swiper)}
            >
                {items.map((button, index) => <React.Fragment key={index}>{button}</React.Fragment>)}
            </Swiper>
        </div>
    );

}