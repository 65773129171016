import React from 'react';
import { Oval } from 'react-loader-spinner'

const Spinner = () => {
    return (
        <Oval height={280} width={280} color="#343a40" wrapperStyle={{}} wrapperClass="" visible={true} ariaLabel='oval-loading'
            secondaryColor="#adb5bd" strokeWidth={5} strokeWidthSecondary={5} />
    )
};

export default Spinner;