import classes from './Kontakt.module.css';
import ContactUs from '../Components/ContactForm';


const Kontakt = () => {
    return (
        <>
            <h1>
                KONKAKT
            </h1>

            <div className={classes.my_div}>
                <div className={classes.my_div}  >
                        <ContactUs></ContactUs>
                </div>
                
                <div className={classes.my_div} >
                    <iframe title="Mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2781.230163299559!2d15.896082976250174!3d45.806648671081625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4765d11531c9ca35%3A0x75bffe054086dd38!2sUl.%20Ivane%20Brli%C4%87%20Ma%C5%BEurani%C4%87%2044%2C%2010000%2C%20Zagreb!5e0!3m2!1shr!2shr!4v1681379148479!5m2!1shr!2shr"  height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                {/*    <iframe width="425" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=15.89639335870743%2C45.8058939810315%2C15.899933874607088%2C45.807561768342524&amp;layer=mapnik"></iframe><br /><small><a href="https://www.openstreetmap.org/#map=19/45.80673/15.89816&amp;layers=N">Prikaži veću kartu</a></small>*/}
                </div>
            </div>

        </>

    )
};

export default Kontakt;