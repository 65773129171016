import React from 'react';
/*import { useParams } from "react-router-dom";*/

import axios from "axios";
import { useState, useEffect } from 'react';
import Spinner from '../Components/Spinner';
import { NavLink, ScrollRestoration } from '../../node_modules/react-router-dom/dist/index';
/*import classes from './Godina.module.css';*/
import classes from './Projekti2.module.css';
import '../App.css';
/*import OneImageProjekti2 from '../Components/OneImageProjekti2';*/

var items = [];


export default function Projekti() {

    /*let { godina } = useParams();*/

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    const xxx = `https://divapi3.kopljar.com/pug?godina=9999`;

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.get(xxx);
                setData(response.data);
                setError(null);
            } catch (err) {
                setError(err.message);
                setData(null);
            } finally {
                setLoading(false);
            }
        };
        getData();
    }, [xxx]);


    if (data) {
        items = data.map(({ rb, objekt, faza, lokacija, opis_zahvata, featured, blur }) =>
            
            <div >
                {/*<hr className={classes.my_hr}></hr>*/}
                <NavLink className={classes.p2_nodecor} to={"/projekt/" + rb}>
                    <div className={classes.p2_my_div}>

                        <div className={classes.p2_my_div_1}>
                            {/*<OneImageProjekti2 url={featured} naslov={objekt} blur={blur}></OneImageProjekti2>*/}
                            <img src={featured} alt={objekt} className={classes.p2_image} style={{ WebkitFilter: 'blur(' + blur + 'px)', filter: 'blur(' + blur + 'px)' }} loading="lazy"></img>
                        </div>

                        <div className={classes.p2_my_div}>
                            <p><b>{objekt}</b></p>
                            <p><i>{opis_zahvata}</i></p>
                            <p>{lokacija.toUpperCase()}</p>
                        </div>
                        
                        
                    </div>
                
                </NavLink>
                <hr className={classes.p2_my_hr}></hr>
            </div>

        );
    }

    return (
        
        <div>
            <div>
                <h1>NAŠI PROJEKTI</h1>
                
            </div>

            <div className="my_separator"></div>
            <div>
                {loading &&
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                        <Spinner></Spinner>
                    </div>
                }
                {error && (
                    <div>{`Problem - ${error}`}</div>
                )}
            </div>




            {
                //items.map((p) =>
                //    < >
                //        {p}
                //    </>
                //)
                items.map((p, index) => <React.Fragment key={index}>{p}</React.Fragment>)
            }
            {/*<hr className={classes.my_hr}></hr>*/}



            </div>
        
    );



}


