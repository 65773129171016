import React, { useState } from 'react';

const Component = ({url, naziv, lokacija1, lokacija2, blur }) => {

    const [isLoaded, setIsLoaded] = useState(false);

    return (
        <a href={url}>
            {isLoaded && (
                <div id="overlay">
                    <div className="overlay_text_naziv" data-text={naziv}>{naziv}</div>
                    
                    <div className="overlay_text_lokacija1" data-text={[...(lokacija1 ? [lokacija1] : []), ...(lokacija2 ? [lokacija2] : [])].join(" , ")}>{lokacija1} {lokacija1 ? ', ' : ''}{lokacija2}</div>
                </div>
            )}

            <div className="image">
                <img key={url} src={url} alt="" className="home_gallery_img"
                    style={{ WebkitFilter: 'blur(' + blur + 'px)', filter: 'blur(' + blur + 'px)' }} loading="lazy"
                    onLoad={() => { setIsLoaded(true) }
                    }
                />

                
                <div className="swiper-lazy-preloader"></div>
            </div>
        </a>
    )

};

export default Component;