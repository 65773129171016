import classes from './Ured.module.css';
import image1 from '../Images/image1.webp';
import '../App.css';

const Ured = () => {
    return (

        <div className={classes.my_div}>


            {/*This is left column*/}
            <div className={classes.my_div} style={{ lineHeight: '2.0rem' }}>
                <div>
                   
                        <h4>DIV Habitat d.o.o.</h4>

                        <div className={classes.my_div} style={{ margin: 0 }}>
                            <address>
                                <p><b>IVANE BRLIĆ MAŽURANIĆ 44</b></p>
                                <p><b>10 090 ZAGREB - SUSEDGRAD</b></p>
                                <p><b>HRVATSKA</b></p>
                                <p><b>TEL/FAX +385 1 8894 884</b></p>
                                <p><b>MAIL: &nbsp; <u><a href="mailto:info@div-habitat.hr" className="nodecor">info@div-habitat.hr</a></u></b></p>
                            </address>
                        </div>
                        <br></br>

                        <p><b>Djelatnost &nbsp;</b> (NKD 2007): 71.11 Arhitektonske djelatnosti</p>
                        <br></br>

                        <p><b>Direktor</b>: Hrvoje Sablić<br></br></p>
                        <p><b>Trgovački sud</b>: Trgovački sud u Zagrebu<br></br></p>
                        <p><b>Godina osnivanja</b>: 2009.<br></br></p>
                        <p><b>Matični broj subjekta</b>: 080699037<br></br></p>
                        <p><b>Kapital</b>: 100% domaći</p>


                        <p></p>
                    
                </div>
            </div>


            {/*This is right column*/}
            <div className={classes.my_div}>


                <div className={classes.wrapper} >
                    <div className={classes.box} style={{ backgroundImage: `url(${image1})`  }}>
                    </div>
                </div>
            </div>

        </div>


    )
};

export default Ured;