import React from 'react';
import Spinner from '../Components/Spinner';
import axios from "axios";
import { useRef, useState, useEffect } from 'react';

// core version + navigation, pagination modules:
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
// import Swiper and modules styles

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/bundle';
import '../App.css';



var items = [];

export default function SwiperSlider({ projektID, blur }) {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    /*const xxx = `https://divapi2.kopljar.com/x?id=${projektID}`;*/
    const xxx = `https://divapi3.kopljar.com/x?id=${projektID}`;

    items = [];

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.get(xxx);
                setData(response.data);
                setError(null);
            } catch (err) {
                setError(err.message);
                setData(null);
            } finally {
                setLoading(false);
            }
        };
        getData();
    }, [xxx]);

    if (data) {
        items = data.map(({ url }) =>
            /*<img key={url} src={url} alt="" className="gallery_img" style={{ filter: 'blur(' + blur + 'px)' }} onDragStart={handleDragStart} role="presentation" />*/
            <SwiperSlide key={url}>
            <a href={url}>
                <img key={url} src={url} alt="" className="gallery_img" style={{ WebkitFilter: 'blur(' + blur + 'px)', filter: 'blur(' + blur + 'px)' }} loading="lazy" />
                    <div className="swiper-lazy-preloader"></div>    
                </a>
            </SwiperSlide>
        );
    }



    return (

        <div>
            {loading &&
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <Spinner></Spinner>
                </div>
            }
            {error && (
                /*<div>{`Problem - ${error}`}</div>*/
                <div>{`Problem - NEMA SLIKA !`}</div>
            )}

            <div className="my_separator"></div>
            <Swiper
               
                autoplay={{
                    delay: 6000,

                }}
                navigation={true}
                

                grabCursor={true}
                crossfade={'true'}

                modules={[Autoplay, Navigation]}
                allowTouchMove='true'
               
            >
                
                {/*{items.map((button) => <>{button}</>)}*/}
                {items.map((button, index) => <React.Fragment key={index}>{button}</React.Fragment>)}

                {/*<SwiperSlide>Slide 1</SwiperSlide>*/}
                {/*<SwiperSlide>Slide 2</SwiperSlide>*/}
                {/*<SwiperSlide>Slide 3</SwiperSlide>*/}
                {/*<SwiperSlide>Slide 4</SwiperSlide>*/}
                
            </Swiper>
            <div className="my_separator"></div>


        </div>




    );

}