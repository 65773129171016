import React from 'react';
import { Link } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons'; 



const Footer = ({theme}) => {


    const year = new Date().getFullYear();

       
    const tema = theme === 'light' ? 'black' : 'white';
    

    return (
        <div>

            
            {/*<hr></hr>*/}
            <div style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 10, paddingBottom: 40 }}>

                <div style={{ display: "inline", float: "left" }}>
                    &copy; {year} &nbsp;- &nbsp;
                    <Link to="/" style={{ textDecoration: 'none' }}>DIV Habitat</Link>

                </div>
                <div style={{ display: "inline", float: "right" }}>
                    <SocialIcon url="https://www.facebook.com/DIV-Habitat-1539772259662523" style={{ height: 25, width: 25 }} bgColor={tema} />

                {/*    {{theme} === 'light' ? */}
                {/*        <SocialIcon url="https://www.facebook.com/DIV-Habitat-1539772259662523" style={{ height: 25, width: 25 }} bgColor='black' />*/}
                {/*        :*/}
                {/*        <SocialIcon url="https://www.facebook.com/DIV-Habitat-1539772259662523" style={{ height: 25, width: 25 }} bgColor='white' />*/}
                
                {/*}*/}

                    
                </div>
            </div>
        </div>
    )
};

export default Footer;