import classes from '../Pages/OneProjekt.module.css';

const OneImage = ({ url, naslov, blur }) => {

    

    if (url && (url[0] !== "")) {
        return (
            <a href={url}>
                <img src={url} alt={naslov} className={classes.op_featured_img} style={{ WebkitFilter: 'blur(' + blur + 'px)', filter: 'blur(' + blur + 'px)' }} loading="lazy"></img>
            </a>
        )
    }
    else {
        return (
            <>{naslov}</>
        )
    }
}

export default OneImage;