import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import classes from './ContactForm.module.css';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';


const ContactUs = () => {
    const form = useRef();

    const initialValues = { user_name: "", user_email: "", message: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [buttonDisabled, setButtonDisabled] = React.useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    };

    useEffect(() => {
        console.log(formErrors);
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            /*Tu bi trebalo slati email !*/
            sendEmail();
            /* console.log(formValues);*/
            setButtonDisabled(true);
        }
    }, [formErrors, buttonDisabled]);

    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.user_name) {
            errors.user_name = "Ime je obavezno!";
        }
        if (!values.user_email) {
            errors.user_email = "E-mail je obavezan!";
        } else if (!regex.test(values.user_email)) {
            errors.user_email = "E-mail nije valjan!";
        }
        if (!values.message) {
            errors.message = "Poruka je obavezna!";
        }
        return errors;
    };




    const sendEmail = (e) => {
        console.log("");
        /*e.preventDefault();*/

        //emailjs.sendForm('service_o44cn7p', 'template_fouzw48', form.current, 'E_tEG8KjYX9OwFP_M')
        //    .then((result) => {
        //        console.log(result.text);
        //    }, (error) => {
        //        console.log(error.text);
        //    });

    };

    return (
        <div className={classes.container}>

            {Object.keys(formErrors).length === 0 && isSubmit ? (
                /*<div className="ui message success">Signed in successfully</div>*/
                <Alert severity="success" className={classes.Alert}>
                    <AlertTitle className={classes.AlertTitle }>Poruka poslana !</AlertTitle>
                    Vaša poruka je poslana. Odgovorit ćemo u <b>najskorije vrijeme!</b>
                </Alert>
            ) : ( <pre>{/*{JSON.stringify(formValues, undefined, 2)}*/}</pre> )}

            {/*<form ref={form} onSubmit={sendEmail}>*/}
            <form ref={form} onSubmit={handleSubmit}>

                <label className={classes.label} for="user_name">Ime</label>
                <input id="user_name" type="text" name="user_name" placeholder="" value={formValues.user_name} onChange={handleChange} />
                <p className={classes.validation_error}>{formErrors.user_name}</p>

                <label className={classes.label} for="email">E-mail</label>
                <input id="email" type="email" name="user_email" placeholder="" value={formValues.user_email} onChange={handleChange} />
                <p className={classes.validation_error}>{formErrors.user_email}</p>

                <label className={classes.label} for="message">Poruka</label>
                <textarea id="message" name="message" placeholder="" value={formValues.message} onChange={handleChange} />
                <p className={classes.validation_error}>{formErrors.message}</p>

                <input type="submit" value="Pošalji" disabled={buttonDisabled} hidden={buttonDisabled} />

            </form>
        </div>
    );
};

export default ContactUs;