import React from 'react';
import { useParams } from "react-router-dom";

import axios from "axios";
import { useState, useEffect } from 'react';
/*import Spinner from '../Components/Spinner';*/
import { NavLink } from '../../node_modules/react-router-dom/dist/index';
import classes from './Godina.module.css';
import OneImageGodina from '../Components/OneImageGodina';

var items = [];


export default function Godina(props) {

    let { godina } = useParams();

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    const xxx = `https://divapi3.kopljar.com/pug?godina=` + godina;

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.get(xxx);
                setData(response.data);
                setError(null);
            } catch (err) {
                setError(err.message);
                setData(null);
            } finally {
                setLoading(false);
            }
        };
        getData();
    }, [xxx]);


    if (data) {
        items = data.map(({rb, objekt, faza, lokacija, opis_zahvata, featured, blur }) =>
            <NavLink className={classes.nodecor} to={"/projekt/" + rb}>
            <div>
                <hr className={classes.my_hr}></hr>
                <div className={classes.my_div_g}>
                        <div className={classes.wrapper_g}>
                        <OneImageGodina url={featured} naslov={objekt} blur={blur}></OneImageGodina>

                            {/*<img src={featured} alt={objekt} style={{ WebkitFilter: 'blur(' + blur + 'px)', filter: 'blur(' + blur + 'px)' }} width='50' height='50' loading="lazy"></img>*/}
                    </div>
                        <div className={classes.my_div_g, classes.my_objekt}>
                            <p className={classes.nodecor}><b>{objekt}</b></p>
                            <p><i>{opis_zahvata}</i></p>
                            <p>{lokacija}</p>
                        </div>
                </div>
                </div>
            </NavLink>
            
        );
    }

    return (
        <div>
            <div>
                <h1>Projekti u {godina}. godini</h1>
            </div>


            <div className={classes.my_container_g}>

                {
                    /*items.map((p) => <div>{p}</div>)*/
                    items.map((p, index) => <React.Fragment key={index}>{p}</React.Fragment>)

                }
                <hr className={classes.my_hr}></hr>
            </div>


        </div>
    );



}


